import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { eventActions } from "../../store/slices/event-slice";
import { fetchWithData } from "../../store/actions/event-actions";
import { paymentActions } from "../../store/slices/payment-slice";

import Select from "../Select/Select";
import Checkbox from "../Checkbox/Checkbox";
import Anchor from "../Anchor/Anchor";
import styles from "./EventInfo.module.css";
import fetchPrice from "../../helpers/fetchPrice";

const EventInfo = (props) => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  const event = useSelector((state) => state.event);
  const [promoItems, setPromoItems] = useState({});
  const dispatch = useDispatch();
  const runner = useSelector((state) => state.runner);
  const isValidated =
      runner.paymentStatus === "PAID" || runner.paymentStatus === "GRATIS"
        ? true
        : false;

  const [eventIsSelected, setEventIsSelected] = useState(false);
  const [errors, setErrors] = useState({});

  const toggleCheckbox = (checked, e) => {
    dispatch(eventActions.toggle(e.target.name));
  };

  useEffect(() => {
    setEventIsSelected(false);
  }, [i18n.language]);

  useEffect(() => {
    setErrors(props.errors);
  }, [props.errors]);

  useEffect(() => {
    if (Object.keys(event.selectedCategory).length !== 0) {
      const { gender } = user;
      const promotionalItems = event.selectedCategory.promotionalItems.reduce((obj, item) => {
        if(item.type.code === gender) {
          obj[item.size] = item.size;
        }

        return obj;
      }, {});
      setPromoItems(promotionalItems);
      fetchPrice(event.selectedCategory.id).then((data) => {
        dispatch(eventActions.updatePriceRsd(data.priceRsd));
        dispatch(eventActions.updatePriceEur(data.priceEur));
      });
    } else {
      dispatch(eventActions.updatePriceRsd(""));
      dispatch(eventActions.updatePriceEur(""));
    }
  }, [event.selectedCategory, dispatch]);

  const eventHandler = (e) => {
    const eventObj = e.target.value;
    setEventIsSelected(true);
    dispatch(eventActions.selectEvent(eventObj));
    dispatch(fetchWithData(eventObj.id, user.id));
    dispatch(paymentActions.resetCheckbox("isMethodChangable"));
  };

  const categoryHandler = (e) => {
    dispatch(eventActions.selectCategory(e.target.value));
  };

  const predictedFinishTimeHandler = (e) => {
    dispatch(eventActions.selectPredictedFinishTime(e.target.value));
  };

  const tShirtHandler = (e) => {
    dispatch(eventActions.selectTShirt(e.target.value));
  };

  const disableCategory =
    (event.alreadyHasData && isValidated) || !eventIsSelected ? "true" : "";

  const disableEvent = props.preselectEventId ? "true" : "";

  const disablePredictedFinishTime =
    Object.keys(event.selectedCategory).length === 0 || (event.alreadyHasData && isValidated) ;

  const disableTShirtSize = Object.keys(event.selectedCategory).length === 0 || (event.alreadyHasData && isValidated) ;

  const disabledCategoryValues =
    event.valuesCategory &&
    event.valuesCategory.map(
      (category) =>
        (category.genderLock !== "BOTH" &&
          category.genderLock !== user.gender) ||
        category.active === false
    );

  const fieldError = (fieldName, formattedName) => {
    let error =
      errors &&
      errors[fieldName] &&
      `${t("field")} ${formattedName} ${t("cant-be-empty")}`;
    return error;
  };

  return (
    <div className={styles.main}>
      <div className={styles.title}>{t("event-info")}</div>
      <div className={styles.info}>
        <Select
          placeholder={t("event")}
          onChange={eventHandler}
          visibleValues={
            props.valuesEvent &&
            props.valuesEvent.map((event) =>
              i18n.language === "sr" ? event.nameSr : event.name
            )
          }
          values={event.valuesEvent}
          preselect={i18n.language === "sr" ? event.selectedEvent.nameSr : event.selectedEvent.name}
          disabled={disableEvent}
          errorMessage={fieldError("event", t("event"))}
        />
        <Select
          placeholder={t("category")}
          onChange={categoryHandler}
          disabled={disableCategory}
          values={event.valuesCategory}
          disabledValues={disabledCategoryValues}
          preselect={event.selectedCategory.name}
          errorMessage={fieldError("category", t("category"))}
        />
        <Select
          placeholder={t("predicted-finish-time")}
          onChange={predictedFinishTimeHandler}
          disabled={disablePredictedFinishTime}
          values={event.selectedCategory.predictedFinishTimes}
          preselect={event.predictedFinishTime}
          errorMessage={fieldError(
            "predictedFinishTime",
            t("predicted-finish-time")
          )}
        />
        <Select
          placeholder={t("shirt-size")}
          disabled={disableTShirtSize}
          preselect={event.tShirtSize}
          onChange={tShirtHandler}
          values={promoItems}
          errorMessage={fieldError("tShirtSize", t("shirt-size"))}
        />
        <div className={styles.notice}>{t("shirt-notice")}</div>
        <div className={styles.checkboxes}>
          <Checkbox
            name="termsOfUse"
            onChange={toggleCheckbox}
            checked={event.termsOfUse}
            error={fieldError("termsOfUse")}
          >
            {t("read-and-agree")}{" "}
            <Anchor
              target="_blank"
               href ={process.env.REACT_APP_WP_URL + "/terms-of-use"}
            >
              {t("terms-of-use")}
            </Anchor>
          </Checkbox>
          <Checkbox
            name="privacyPolicy"
            onChange={toggleCheckbox}
            checked={event.privacyPolicy}
            error={fieldError("privacyPolicy")}
          >
            {t("read-and-agree")}{" "}
            <Anchor
              target="_blank"
              href={process.env.REACT_APP_WP_URL + "/disclaimer"}
            >
              {t("disclaimer")}.
            </Anchor>
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

export default EventInfo;
